<nav>
  <ul>
    <li>
      <a class="app-main-menu__item" [routerLink]="'/ranking'" routerLinkActive="app-main-menu__item--active">
        <img src="/assets/icons/icon-ranking.svg" height="20" width="22" alt="">
        {{ "menu.ranking" | translate }}
      </a>
    </li>
    <li>
      <a class="app-main-menu__item" [routerLink]="'/my-band'" routerLinkActive="app-main-menu__item--active">
        <img src="/assets/icons/icon-bandroom.svg" height="20" width="22" alt="">
        {{ "menu.my-band" | translate }}
      </a>
    </li>
    <li>
      <a class="app-main-menu__item" [routerLink]="'/'" routerLinkActive="app-main-menu__item--active"
         [routerLinkActiveOptions]="{exact: true}">
        <img src="/assets/icons/icon-home.svg" height="20" width="22" alt="">
        {{ "menu.home" | translate }}
      </a>
    </li>
    <li>
      <a class="app-main-menu__item" [routerLink]="'/events'" routerLinkActive="app-main-menu__item--active">
        <img src="/assets/icons/icon-event.svg" height="20" width="22" alt="">
        {{ "menu.events" | translate }}
      </a>
    </li>
    <li>
      <a class="app-main-menu__item" [routerLink]="'/more'" routerLinkActive="app-main-menu__item--active">
        <img src="/assets/icons/icon-more.svg" height="20" width="22" alt="">
        {{ "menu.more" | translate }}
      </a>
    </li>
  </ul>
</nav>
